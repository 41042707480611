import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
    name: 'filteredId',
    initialState: {filteredId: null,},
    reducers: {
        updateFilteredId(state, action) {
            state.filteredId = action.payload;
        },
    }
});

export { actions as filteredIdActions };
export { reducer as filteredIdReducer };
