import React, {useState, useCallback} from 'react';
import { Grid, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';
import ReportLayoutPage from './ReportLayoutPage';
import ReportFilter from './ReportFilter';
import Graph from './Graph';
import { useAttributePreference } from '../common/preferences';
import { formatDate } from '../common/formatter';
import { speedFromKnots } from '../common/converter';
import t from '../common/localization';

const Filter = ({ children, setItems }) => {

  const speedUnit = useAttributePreference('speedUnit');
  const [openSnackbar, setOpenSnackbar] = useState(false)

  const handleSubmit = useCallback(  async (deviceId, from, to, mail, headers) => {
    const query = new URLSearchParams({ deviceId, from, to, mail });
    const response = await fetch(`/api/reports/routeghg?${query.toString()}`, { headers });

    if (response.ok) {
      const res = await response.json()
      setItems(res);
      if (res[0].positions.length === 0)
        setOpenSnackbar(true)
      const positions = res[0].positions;
      let agtleg = res[0].agtleg || [];
      let gtileg = res[0].gtileg || [];
      let ghgEmission = res[0].ghgEmission || [];
      let blackCarbonGHG = res[0].blackCarbonEmission || [];

      var i = 0
      const roundEighthDigit = (number, digits) => {
        if (number === undefined)
          return ;
        const roundedNumber = parseFloat(number.toFixed(digits)); // Round to 8 decimal places
        const multiplier = Math.pow(10, digits);
        // const roundedWith8thDigit = Math.round(roundedNumber * 1e8) / 1e8;
        const roundedWith8thDigit = Math.round(roundedNumber * multiplier) / multiplier; // Round the 8th digit
        return roundedWith8thDigit;
      }

      let formattedPositions = positions.map(position => {
        return {
          speed: Number(speedFromKnots(position.speed, speedUnit)),
          //altitude: position.altitude
          tanklevel: position.attributes.tanklevel,
          accuracy: position.accuracy,
          longitude:position.longitude,
          latitude:position.latitude,
          fuel_Level:position.attributes.fuelelevel,
          fuelpressure:position.attributes.fuelpressure,
          enginetemp:position.attributes.enginetemp,
          rpm:position.attributes.rpm,
          kph:position.attributes.kph,
          oiltemp:position.attributes.oiltemp,
          fuelrate:position.attributes.fuelrate,
          torque:position.attributes.torque,
          batteryvoltage:position.attributes.batteryvoltage,
          gpsSgn:position.attributes.gpsSgn,
          gsmSgn:position.attributes.gsmSgn,
          // fuel_volume:position.attributes.fuel_volume,
          fixTime: formatDate(position.fixTime) ,
          distance:position.attributes.distance ,
          debug:position.attributes.debug,
          ghgemission: agtleg[i++],
          ghgtank_to_wheels: gtileg[i-1],
          GHGEmission: roundEighthDigit(ghgEmission[i-1], 8),
          blackCarbon: roundEighthDigit(blackCarbonGHG[i-1], 10)
        }
      });
      setItems(formattedPositions);
    }
  },[setItems, speedUnit])

  const closeSnackbar = useCallback( () => {
    setOpenSnackbar(false)
  },[])

  return (
      <>
        <ReportFilter handleSubmit={handleSubmit} showOnly openSnackbar={openSnackbar} onCloseSnackbar={closeSnackbar} />
        {children}
      </>
  )
}

const ChartType = ({ types, setTypes }) => {

  const handleChange = (event) => {
    setTypes(event.target.value);
  };

  return (
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6}>
          <FormControl variant="filled" margin="normal" fullWidth>
            <InputLabel>{t('reportChartType')}</InputLabel>
            <Select multiple value={types} onChange={handleChange}>
              <MenuItem value="speed">{t('positionSpeed')}</MenuItem>
              <MenuItem value="accuracy">{t('positionAccuracy')}</MenuItem>
              <MenuItem value="latitude">{t('positionLatitude')}</MenuItem>
              <MenuItem value="tanklevel">{t('positionTanklevel')}</MenuItem>
              <MenuItem value="longitude">{t('positionLongitude')}</MenuItem>
              <MenuItem value="fuel_Level">{t('fuelelevel')}</MenuItem>
              <MenuItem value="fuelpressure">{t('fuelpressure')}</MenuItem>
              <MenuItem value="enginetemp">{t( 'enginetemp')}</MenuItem>
              <MenuItem value="rpm">{t('rpm')}</MenuItem>
              <MenuItem value="kph">{t('kph')}</MenuItem>
              <MenuItem value="oiltemp">{t('oiltemp')}</MenuItem>
              <MenuItem value="fuelrate">{t('fuelrate')}</MenuItem>
              <MenuItem value="torque">{t('torque')}</MenuItem>
              <MenuItem value="batteryvoltage">{t('batteryvoltage')}</MenuItem>
              <MenuItem value="gpsSgn">{t('gpsSgn')}</MenuItem>
              <MenuItem value="gsmSgn">{t('gsmSgn')}</MenuItem>
              {/*<MenuItem value="fuel_volume">{'Fuel Volume'}</MenuItem>*/}
              <MenuItem value="distance">{t('sharedDistance')}</MenuItem>
              <MenuItem value="debug">{t( 'debug')}</MenuItem>
              {/*<MenuItem value="ghgemission">{t('reportGHGEmission')}</MenuItem>*/}
              {/*<MenuItem value="ghgtank_to_wheels">{t('reportGHGtank_to_wheels')}</MenuItem>*/}
              <MenuItem value="GHGEmission">{t('GHG_Emission')}</MenuItem>
              <MenuItem value="blackCarbon">{t('blackCarbonGHG')}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
  )
}


const ChartReportPage = () => {

  const [items, setItems] = useState([]);
  const [types, setTypes] = useState(['speed']);

  return (
      <ReportLayoutPage filter={
        <Filter setItems={setItems}>
          <ChartType types={types} setTypes={setTypes} />
        </Filter>
      }>
        <Graph items={items} types={types} />
      </ReportLayoutPage>
  )
}

export default ChartReportPage;
