import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Dialog from '@material-ui/core/Dialog';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Divider from '@material-ui/core/Divider';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import LocationOnIcon from "@material-ui/icons/LocationOn";
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListAltIcon from "@material-ui/icons/ListAlt";
import ReplayIcon from "@material-ui/icons/Replay";
import NotificationImportantIcon from '@material-ui/icons/NotificationImportant';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import {useSelector} from "react-redux";
import t from "./common/localization";
//import {devicesActions} from "./store";
//import React, {Fragment, useState} from 'react';
//import Button from '@material-ui/core/Button';

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: 'relative',
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const ActionDialog = ({open ,history , handleClose , itemId, deviceId, onShowDetails}) => {
    const classes = useStyles();
    const subadminEnabled = useSelector(state => state.session.user && state.session.user.subadmin)
    //const items = useSelector(state => Object.values(state.devices.items));
    const position = useSelector(state => state.positions.items[itemId]);

    const handleClick = () => {
        handleClose()
            history.push(`/position/${position.id}`);
    };

    const handleCommand = (itemId) => {
        handleClose()
        history.push(`command/${itemId}`);

    }
    const handlePlayback = (itemId) => {
        handleClose()
        history.push(`replay/${itemId}`);
    }

    const handleAlerts = (itemId) => {
        handleClose()
        history.push(`reports/event/${itemId}`);
    }


    return (
        <div>

            <Dialog fullScreen open={open} onClose={ handleClose} TransitionComponent={Transition}>
                <AppBar className={classes.appBar}>
                    <Toolbar>
                        <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                        <Typography variant="h6" className={classes.title}>
                            {t('moreactions')}
                        </Typography>

                    </Toolbar>
                </AppBar>
                <List>

                    <ListItem button  onClick={() => handleClick() }>
                        <ListItemIcon>
                            <ListAltIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Details"  />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={handleClose} aria-label="close" >
                        <ListItemIcon>
                            <LocationOnIcon />
                        </ListItemIcon>
                        <ListItemText primary="Tracking"  />
                    </ListItem>
                    <Divider />
                    <ListItem button onClick={() => handlePlayback(itemId)} >
                        <ListItemIcon>
                            <ReplayIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Playback"  />
                    </ListItem>

                    <Divider />
                    {subadminEnabled && (
                    <ListItem button  onClick={() => handleCommand(itemId)}>
                        <ListItemIcon>
                            <ArrowForwardIosIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Commands"  />
                    </ListItem>
                    )}
                    <Divider />
                    <ListItem button  onClick={() => handleAlerts(itemId)}>
                        <ListItemIcon>
                            <NotificationImportantIcon/>
                        </ListItemIcon>
                        <ListItemText primary="Alerts"  />
                    </ListItem>

                </List>
            </Dialog>
        </div>
    );
}
export default ActionDialog;