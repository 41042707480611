import React, {useEffect, useState} from 'react';
import {useLocation} from "react-router-dom";

export default function ConfirmEmail() {

    const [htmlContent, setHtmlContent] = useState('');
    const location = useLocation();

    useEffect( () => {
        const fetchHtml = async () => {
            try {
                const token = new URLSearchParams(location.search).get('token');
                const response = await fetch(`/api/users/verifyEmail?token=${token}`);
                if (response.ok){
                    const html = await response.text();
                    setHtmlContent(html);
                }
            }catch (e) {
                console.log(e)
            }
        }

        fetchHtml();
    },[location.search])
    return (
        <h1 dangerouslySetInnerHTML={{ __html: htmlContent }}></h1>
    );
}

