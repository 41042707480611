import React, { useState } from 'react';
import TextField from '@material-ui/core/TextField';
import Snackbar from "@material-ui/core/Snackbar";
import Alert from "@material-ui/lab/Alert";
import t from './common/localization';
import userAttributes from './attributes/userAttributes';
import EditItemView from './EditItemView';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  Typography,
  Grid,
  CircularProgress, FormControlLabel, Checkbox
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import EditAttributesView from './attributes/EditAttributesView';
import LinkField from './form/LinkField';
import {useSelector} from "react-redux";
import Button from "@material-ui/core/Button";
import CloudUploadIcon from "@material-ui/icons/CloudUpload";
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(() => ({
  details: {
    flexDirection: 'column',
  },
}));

const UserPage = () => {
  const classes = useStyles();
  const adminEnabled = useSelector(state => state.session.user && state.session.user.administrator);
  const [item, setItem] = useState();
  const subadminEnabled = useSelector(state => state.session.user && state.session.user.subadmin)
  const [file, setFile] = useState(null);
  const [uploading, setUploading] = useState(false);
  const [open, setOpen] = React.useState(false);
  const [message, setMessage] = useState('');

  const handleFileChange = (event) => {
    const selectedFile = event.target.files[0];
    const fileSizeKB = selectedFile.size / 1024;
    const allowedExtensions = [".gpg", ".png", ".jpg", ".jpeg"]; // Add the desired image extensions here
    const fileExtension = selectedFile.name.split(".").pop();

    if (fileSizeKB < 50 && allowedExtensions.includes(`.${fileExtension.toLowerCase()}`)) {
      setFile(selectedFile);
    } else {
      if (fileSizeKB > 50) {
        setOpen(true)
        setMessage(t("fileSizeMessage"))
      } else {
        setOpen(true)
        setMessage(t("fileFormatMessage"))
      }
    }
  };

  const handleSubmit = async () => {
    setUploading(true);
    const formData = new FormData();
    formData.append('logo', file);
    const response = await fetch('/api/users/addlogo', {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: file
    });
    if (response.ok) {
      setUploading(false);
      setFile(null);
      setOpen(true)
      setMessage(t("logoUploadSuccessfully"))
    } else {
      setOpen(true)
      setMessage(t("logoNotUploadSuccessfully"))
    }
  };

  function uploadingmessage() {
    return message
  }

  function statusMessage() {
    if (message === t("logoUploadSuccessfully"))
      return "success"
    else
      return "error"
  }

  return (
      <EditItemView endpoint="users" item={item} setItem={setItem}>
        {item &&
        <>
          <Accordion defaultExpanded>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
              <Typography variant="subtitle1">
                {t('sharedRequired')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <TextField
                  margin="normal"
                  value={item.name || ''}
                  onChange={event => setItem({...item, name: event.target.value})}
                  label={t('sharedName')}
                  variant="filled"
                  required/>
              <TextField
                  margin="normal"
                  value={item.email || ''}
                  onChange={event => setItem({...item, email: event.target.value})}
                  label={t('userEmail')}
                  variant="filled"
                  required/>
              <TextField
                  margin="normal"
                  value={item.phone || ''}
                  onChange={event => setItem({...item, phone: event.target.value})}
                  label={t('sharedPhone')}
                  variant="filled"
                  required/>
              {adminEnabled && (
                  <TextField
                      margin="normal"
                      value={item.organization || ''}
                      onChange={event => setItem({...item, organization: event.target.value})}
                      label={t('userOrganization')}
                      variant="filled"/>)}
              {subadminEnabled && (
                  <TextField
                      margin="normal"
                      type="password"
                      onChange={event => setItem({...item, password: event.target.value})}
                      label={t('userPassword')}
                      variant="filled"
                      required/>)}

              {!adminEnabled && subadminEnabled && (
                  <Typography>
                    <form>
                      <Box marginTop={2}>
                        <Grid container spacing={0} alignItems="center">
                          <Grid item xs={6}>
                            <Button
                                variant="contained"
                                color="default"
                                startIcon={<CloudUploadIcon/>}
                                component="label"
                            >
                              {t("selectLogo")}
                              <input
                                  type="file"
                                  onChange={handleFileChange}
                                  hidden
                              />
                            </Button>
                          </Grid>
                          <Grid item xs={6}>
                            <Button
                                variant="contained"
                                color="default"
                                onClick={handleSubmit}
                                disabled={!file || uploading}
                                fullWidth
                            >
                              {uploading ? <CircularProgress size={24}/> : t("upload")}
                            </Button>
                          </Grid>
                        </Grid>
                      </Box>
                    </form>
                  </Typography>
              )}
            </AccordionDetails>
          </Accordion>
          <Snackbar open={open} autoHideDuration={8000} onClose={() => setOpen(false)}>
            <Alert onClose={() => setOpen(false)} severity={statusMessage()}>
              {uploadingmessage()}
            </Alert>
          </Snackbar>

          {subadminEnabled && <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
              <Typography variant="subtitle1">
                {t('sharedAttributes')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <EditAttributesView
                  attributes={item.attributes}
                  setAttributes={attributes => setItem({...item, attributes})}
                  definitions={userAttributes}
              />
            </AccordionDetails>
          </Accordion>}

          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
              <Typography variant="subtitle1">
                {t('sharedPermissions')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <FormControlLabel
                  control={<Checkbox checked={item.readonly}
                  onChange={event => setItem({...item, readonly: event.target.checked})}/>}
                  label={t('userReadonly')}/>
            </AccordionDetails>
            <AccordionDetails className={classes.details}>
              <FormControlLabel
                  control={<Checkbox checked={item.deviceReadonly}
                  onChange={event => setItem({...item, deviceReadonly: event.target.checked})}/>}
                  label={t('userDeviceReadonly')}/>
            </AccordionDetails>
          </Accordion>

          {item.id && subadminEnabled &&
          <Accordion>
            <AccordionSummary expandIcon={<ExpandMoreIcon/>}>
              <Typography variant="subtitle1">
                {t('sharedConnections')}
              </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.details}>
              <LinkField
                  margin="normal"
                  endpointAll="/api/devices?all=true"
                  endpointLinked={"/api/devices?userId=" + item.id}
                  baseId={item.id}
                  keyBase="userId"
                  keyLink="deviceId"
                  label={t('deviceTitle')}
                  variant="filled"/>
              <LinkField
                  margin="normal"
                  endpointAll="/api/groups?all=true"
                  endpointLinked={"/api/groups?userId=" + item.id}
                  baseId={item.id}
                  keyBase="userId"
                  keyLink="groupId"
                  label={t('settingsGroups')}
                  variant="filled"/>
            </AccordionDetails>
          </Accordion>
          }
        </>
        }
      </EditItemView>
  );
}

export default UserPage;
