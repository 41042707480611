import React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';

 const  FormDialog = ({refreshGeofences, open,handleClose,newItem , setNewItem}) => {
     const handleChange = (e) => {
         setNewItem({...newItem, name: e.target.value}) ;
     }
     const handleAdd = async () => {
         const response = await fetch('/api/geofences', {
             method: 'POST',
             headers: {'Content-Type': 'application/json'},
             body: JSON.stringify(newItem),
         });
         if (response.ok) {
             await handleClose();
             refreshGeofences()
         }
     }
    return (
        <div>
            <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Geofence Region</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Enter the name of this region.
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Geofence Name"
                        type="email"
                        fullWidth
                        onChange={handleChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleAdd} color="primary">
                        Subscribe
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export { FormDialog };
