import React, {useState} from 'react';
import { isWidthUp, makeStyles, withWidth } from '@material-ui/core';
import Drawer from '@material-ui/core/Drawer';
import ContainerDimensions from 'react-container-dimensions';
import MainToolbar from './MainToolbar';
import Map from './map/Map';
import CurrentLocationMap from './map/CurrentLocationMap';
import GeofenceEditMap from './map/GeofenceEditMap';
import GeofencesList from './GeofencesList';
import {FormDialog} from "./map/FormDialog";
import { geofencesActions } from './store';
import {useDispatch, useSelector} from "react-redux";

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  content: {
    flexGrow: 1,
    overflow: 'hidden',
    display: 'flex',
    flexDirection: 'row',
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column-reverse',
    }
  },
  drawerPaper: {
    position: 'relative',
    [theme.breakpoints.up('sm')]: {
      width: 350,
    },
    [theme.breakpoints.down('xs')]: {
      height: 250,
    }
  },
  mapContainer: {
    flexGrow: 1,
  },
}));

const GeofencesPage = ({ width }) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const subadminEnabled = useSelector(state => state.session.user && state.session.user.subadmin)


  const refreshGeofences = async () => {
    const response = await fetch('/api/geofences');
    if (response.ok) {
      let res = await response.json();
      dispatch(geofencesActions.refresh(res));
    }
  };

  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const [newItem, setNewItem] = useState({ name: '', area: ''});

  return (
    <div className={classes.root}>
      <MainToolbar />
      <div className={classes.content}>

        <Drawer
          anchor={isWidthUp('sm', width) ? 'left' : 'bottom'}
          variant='permanent'
          classes={{ paper: classes.drawerPaper }}>
          <GeofencesList />
        </Drawer>

        <div className={classes.mapContainer}>
          <ContainerDimensions>
            <Map>
              <CurrentLocationMap />
              {subadminEnabled &&
              <GeofenceEditMap refreshGeofences={refreshGeofences} newItem={newItem} setNewItem={setNewItem} handleClickOpen={handleClickOpen}/>
              }
            </Map>
          </ContainerDimensions>

        </div>
        <FormDialog refreshGeofences={refreshGeofences} newItem={newItem} setNewItem={setNewItem} open={open} handleClose={handleClose}
        />
      </div>
    </div>
  );
}

export default withWidth()(GeofencesPage);
