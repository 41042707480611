import React from 'react';

const checkEmailMessage = () => {

    return (
        <>
            <h1>Reset Your Password</h1>
            <p>Please check your email for instructions to reset your password.</p>
            <style>
                {`.verify-mail-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;
    background-color: #f5f5f5;
    font-family: Arial, Helvetica, sans-serif;
    text-align: center;
}

.verify-mail-container h1 {
    font-size: 2.5rem;
    margin-bottom: 2rem;
}

.verify-mail-container p {
    font-size: 1.2rem;
    line-height: 1.5;
    margin-bottom: 2rem;
}

@media (max-width: 768px) {
    .verify-mail-container {
        padding: 2rem;
    }

    .verify-mail-container h1 {
        font-size: 2rem;
    }

    .verify-mail-container p {
        font-size: 1rem;
    }
}
                `}
            </style>
        </>
    )
}

export default checkEmailMessage;
