import t from '../common/localization'

export default {
  'speedLimit': {
    name: t('attributeSpeedLimit'),
    type: 'string',
  },
  'report.ignoreOdometer': {
    name: t('attributeReportIgnoreOdometer'),
    type: 'boolean',
  },
  'activateOBDII': {
    name: t('activateOBDII'),
    type: 'boolean',
  },
  'energyConsmptionEmpty': {
    name: t('attributeEnergyConsmptionEmpty'),
    type: 'string',
  },
  'energyConsmptionFull': {
    name: t('attributeEnergyConsmptionFull'),
    type: 'string',
  },
  // 'capa': {
  //   name: t('attributeCapa'),
  //   type: 'string',
  // },
  // 'weight': {
  //    name: t('weight'),
  //    type: 'string',
  //   },
  'EFCategory': {
     name: t('EFCategory'),
     type: 'category',
  },
  'EFUnit': {
     name: t('EFUnit'),
     type: 'unit',
  },
  'region': {
    name: t('sharedTypeRegion'),
    type: 'string',
  },
  'fuelType': {
    name: t('sharedTypeFuelType'),
    type: 'string',
  },
  'PL': {
    name: t('attributePL'),
    type: 'string',
  },
};
