import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import CircularProgress from '@material-ui/core/CircularProgress';
import CssBaseline from '@material-ui/core/CssBaseline';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '@material-ui/core/Paper';
import { Button, FormControl, Grid, InputLabel, Link, makeStyles, MenuItem, Select, TextField } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import VisibilityOffIcon from '@material-ui/icons/VisibilityOff';
import { sessionActions } from '../../store';
import t from '../../common/localization';

//import StartPage from './../../StartPage';

// function Copyright() {
//   return (
//       <Typography variant="body2" color="textSecondary" align="center">
//         {'Copyright © '}
//         <Link color="inherit" href="https://material-ui.com/">
//           Your Website
//         </Link>{' '}
//         {new Date().getFullYear()}
//         {'.'}
//       </Typography>
//   );
// }

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
  },
  image: {
    backgroundImage: 'url(/loginpage.png)',
    backgroundRepeat: 'no-repeat',
    backgroundColor:theme.palette.type === 'light' ? theme.palette.grey[50] : theme.palette.grey[900],
    backgroundSize: 'cover',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },

}));

export default function LoginForm() {
  const classes = useStyles();

  const dispatch = useDispatch();
  const history = useHistory();

  const [errormsg, setErrormsg] = useState('');
  const [loading, setLoading] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);

  const registrationEnabled =  useSelector(state => state.session.server ? state.session.server['registration'] : false);
  const emailEnabled =  useSelector(state => state.session.server ? state.session.server['emailEnabled'] : false);

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  }

  const handlePasswordChange = (event) => {
    setPassword(event.target.value);
  }

  const handleTogglePasswordVisibility = () => {
    setShowPassword((prevShowPassword) => !prevShowPassword);
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    setLoading(true)
    const response = await fetch('/api/session', { method: 'POST', body: new URLSearchParams(`email=${email}&password=${password}`) });
    if (response.ok) {
      const user1 = await response.json();
      const user = user1.user;
      const tokenThingwings = user1.token;
      localStorage.setItem("tokenThingwings", tokenThingwings)
      dispatch(sessionActions.updateUser(user));
      setLoading(false)
      history.push('/');
    }
    else if (response.status === 400){
      const errorText = await response.text();
      setErrormsg(t(errorText.split(' ')[0]))
      setLoading(false)
      setPassword('');    }
    else {
      setErrormsg(t('UnknownProblem'))
      setLoading(false)
      setPassword('');
    }
  }

  const handleSpecialKey = e => {
    if (e.keyCode === 13 && email && password) {
      handleLogin(e);
    }
  }

  const handleForgetPassword = async (event) => {
    history.push('/forgotPassword')
  }

  return (
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        <Grid item xs={false} sm={4} md={7} className={classes.image}  />
        <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            <img  src='/logo.png' alt='Trac thing' style={{"width":300}}/>

            <TextField
                required
                fullWidth
                error={!!errormsg}
                label={t('userEmail')}
                name='email'
                value={email}
                autoComplete='email'
                autoFocus
                onChange={handleEmailChange}
                onKeyUp={handleSpecialKey}
                helperText={errormsg && errormsg}
                variant='filled'
                className={classes.submit}
            />

            <TextField
                required
                fullWidth
                error={!!errormsg}
                label={t('userPassword')}
                name='password'
                value={password}
                type={showPassword ? 'text' : 'password'}
                autoComplete='current-password'
                onChange={handlePasswordChange}
                onKeyUp={handleSpecialKey}
                variant='filled'
                className={classes.submit}
                InputProps={{
                  endAdornment: (
                      <InputAdornment position="end">
                        <IconButton onClick={handleTogglePasswordVisibility} edge="end">
                          {showPassword ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </IconButton>
                      </InputAdornment>
                  ),
                }}
            />

            <Button
                onClick={handleLogin}
                onKeyUp={handleSpecialKey}
                variant='contained'
                className={classes.submit}
                color='secondary'
                disabled={!email || !password}
                fullWidth>
              {t('loginLogin')}
            </Button>

            <Grid item container>
              <Button onClick={() => history.push('/register')} disabled={!registrationEnabled} color="secondary">
                {t('loginRegister')}
              </Button>
              <Grid item xs>
                <FormControl variant="filled" fullWidth>
                  <InputLabel>{t('loginLanguage')}</InputLabel>
                  <Select>
                    <MenuItem value="en">English</MenuItem>
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            {emailEnabled &&
            <Grid item container justifyContent="flex-end">
              <Link onClick={() => handleForgetPassword(email)} className={classes.resetPassword} underline="none"
                    style={{ cursor: 'pointer' }}
                    onMouseEnter={(e) => e.target.style.color = 'blue'}
                    onMouseLeave={(e) => e.target.style.color = 'inherit'}>{t('loginReset')}</Link>
              {errormsg === 'your email not correct' &&  <span className={classes.errorMessage}>
                    <br />
                    <span style={{ color: 'red' }}>email not correct</span>
                   </span>}
            </Grid>
            }
          </div>

          {loading && <div className={classes.loading}>
            <CircularProgress color="secondary" />
          </div>}

        </Grid>
      </Grid>
  );
}