import 'mapbox-gl/dist/mapbox-gl.css';
import '@mapbox/mapbox-gl-draw/dist/mapbox-gl-draw.css';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import theme from '@mapbox/mapbox-gl-draw/src/lib/theme';
import {useEffect} from 'react';

import { useSelector } from 'react-redux';
import { map } from './Map';
import { geofenceToFeature, geometryToArea } from './mapUtil';

const draw = new MapboxDraw({
  displayControlsDefault: false,
  controls: {
    polygon: true,
    trash: true,
    line_string: true, // Turns off this drawing tool

  },
  userProperties: true,
  styles: [...theme, {
    id: 'gl-draw-title',
    type: 'symbol',
    filter: ['all'],
    layout: {
      'text-field': '{user_name}',
      'text-font': ['Roboto Regular'],
      'text-size': 12,
    },
    paint: {
      'text-halo-color': 'white',
      'text-halo-width': 1,
    },
  }],
});

const GeofenceEditMap = ({handleClickOpen , newItem , setNewItem , refreshGeofences }) => {

  const geofences = useSelector((state) => Object.values(state.geofences.items));

  useEffect(() => {
        refreshGeofences();

        map.addControl(draw, 'top-left');

        map.on('draw.create', async (event) => {
          const feature = event.features[0];
          setNewItem({ ...newItem , area : geometryToArea(feature.geometry)});
          handleClickOpen();
        });

        map.on('draw.delete', async (event) => {
          const feature = event.features[0];
          const response = await fetch(`/api/geofences/${feature.id}`, { method: 'DELETE' });
          if (response.ok) {
            refreshGeofences();
          }
        });

        map.on('draw.update', async (event) => {
          const feature = event.features[0];
          const item = geofences.find((i) => i.id === feature.id);
          if (item) {
            const updatedItem = { ...item, area: geometryToArea(feature.geometry) };
            const response = await fetch(`/api/geofences/${feature.id}`, {
              method: 'PUT',
              headers: { 'Content-Type': 'application/json' },
              body: JSON.stringify(updatedItem),
            });
            if (response.ok) {
              refreshGeofences();
            }
          }
        });

        return () => map.removeControl(draw);
      }, []);

  useEffect(() => {
    draw.deleteAll();
    geofences.forEach((geofence) => {
      draw.add(geofenceToFeature(geofence));
    });
  }, [geofences]);


  return null;
};

export default GeofenceEditMap;
