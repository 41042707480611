import React, {useState} from 'react';
import {Provider, useDispatch, useSelector} from 'react-redux';
import { alpha, makeStyles } from '@material-ui/core/styles';
import {Paper, Grid} from '@material-ui/core';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';

import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useHistory } from 'react-router-dom';
import store, { devicesActions } from './store';
import { useEffectAsync } from './reactHelper';
import t from "./common/localization";
import LocationOnIcon from '@material-ui/icons/LocationOn';
import ReplayIcon from '@material-ui/icons/Replay';
import ListAltIcon from '@material-ui/icons/ListAlt';
import EditCollectionViewDevice from "./EditCollectionViewDevice";
import {map} from "./map/Map";
import SignalCellularAltIcon from "@material-ui/icons/SignalCellularAlt";
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import InputBase from '@material-ui/core/InputBase';
import SearchIcon from '@material-ui/icons/Search';
import ReactDOM from "react-dom";
import StatusView from "./map/StatusView";
import mapboxgl from "mapbox-gl";
import Fab from "@material-ui/core/Fab";
import AddIcon from "@material-ui/icons/Add";
import Tooltip from "@material-ui/core/Tooltip";

const useStyles = makeStyles((theme) => ({
  margin: {
    textTransform: 'none',
  },
  list: {
    maxHeight: '100%',
    overflow: 'auto',

  },
  green: {
    color: theme.palette.common.green,
  },
  red: {
    color: theme.palette.common.red,
  },
  gray: {
    color: theme.palette.common.gray,
  },
  yellow: {
    color: theme.palette.common.yellow,
  },
  icon: {
    width: '25px',
    height: '25px',
    filter: 'brightness(0) invert(1)',
  },
  configue: {
    color: 'red'
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginRight: theme.spacing(),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(-1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('md')]: {
      width: '20ch',
    },
  },
  fab: {
    color: 'rgb(51, 51, 102)',
    backgroundColor: "white",
    cursor: "pointer",
    '&:hover': {
      backgroundColor: 'rgb(230, 230, 230)',
    }
  },
}));

const getStatusColor = (status) => {
  switch (status) {
    case 'online':
      return 'green';
    case 'offline':
      return 'gray';
    case 'slowing':
      return 'yellow';
    default:
      return 'red';
  }
};

//FFBF00
const DeviceView = ({ updateTimestamp, onMenuClick}) => {

  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const items = useSelector(state => Object.values(state.devices.items));
  const positions = useSelector(state => Object.values(state.positions));


  const [deviceName, setDeviceName] = useState('');

  const handleClick = (id) => {
    history.push(`/position/`+id);
  };

  const onZoom = async (id) => {
    Array.from(map.getContainer().getElementsByClassName('mapboxgl-popup')).forEach(el => el.remove());
    const position = positions[0][id];
    if (position) {
      // Start flying to the new location
      await new Promise((resolve) => {
        map.flyTo({
          center: [position.longitude, position.latitude],
          zoom: 15,
          essential: true // This ensures the animation runs
        });
        // Listen for the 'moveend' event to know when the flyTo is complete
        map.once('moveend', resolve);
      });

      // Create the popup after the flyTo animation is finished
      const placeholder = document.createElement('div');
      ReactDOM.render(
          <Provider store={store}>
            <StatusView deviceId={id} onShowDetails={positionId => history.push(`/position/${positionId}`)} history={history}/>
          </Provider>,
          placeholder
      );

      new mapboxgl.Popup({
        offset: 25,
        anchor: 'top'
      })
          .setDOMContent(placeholder)
          .setLngLat([position.longitude, position.latitude]) // Note: should be [longitude, latitude]
          .addTo(map);
    }
  };

  useEffectAsync(async () => {
    const response = await fetch('/api/devices');
    if (response.ok) {
      dispatch(devicesActions.refresh(await response.json()));
    }
  }, [updateTimestamp]);

  const color = (id) => {
    if (positions[0][id]) {
      if (positions[0][id].attributes.gpsSgn<3)
        return 'red';
      else if (positions[0][id].attributes.gpsSgn>=3 && positions[0][id].attributes.gpsSgn<5)
        return 'yellow'
      else
      return 'green'
    }
    else
      return 'blue';
  }

  const filteredItems = deviceName === '' ? items : items.filter(item => {
    const regex = new RegExp(deviceName.toLowerCase().split('').join('.*'), 'i');
    return regex.test(item.name.toLowerCase())
    // [...deviceName.toLowerCase()].some(char => item.name.toLowerCase().includes(char))
    || item.uniqueId.startsWith(deviceName)
  });

  const handleChange = (event) => {
    setDeviceName(event.target.value);
  };

  const timeDuration = (item) => {
    const now = new Date();
    if (positions[0][item.id]) {
      if (item.status === 'slowing' || item.status === 'online')
        return`${positions[0][item.id].speed.toFixed(0)}Km/h`
      const deviceDate = new Date(positions[0][item.id].deviceTime);
      const diffInSeconds = Math.floor((now - deviceDate) / 1000);
      if (diffInSeconds < 60) {
        return `Since ${1} minute`;
      } else if (diffInSeconds < 3600) {
        const minutes = Math.floor(diffInSeconds / 60);
        return `Since ${minutes} minute${minutes !== 1 ? 's' : ''}`;
      } else if (diffInSeconds < 86400) {
        const hours = Math.floor(diffInSeconds / 3600);
        return `Since ${hours} hour${hours !== 1 ? 's' : ''}`;
      } else if (diffInSeconds < 2592000) {
        const days = Math.floor(diffInSeconds / 86400);
        return `Since ${days} day${days !== 1 ? 's' : ''}`;
      } else if (diffInSeconds < 31536000) {
        const months = Math.floor(diffInSeconds / 2592000);
        return `Since ${months} month${months !== 1 ? 's' : ''}`;
      } else {
        const years = Math.floor(diffInSeconds / 31536000);
        return `Since ${years} year${years !== 1 ? 's' : ''}`;
      }
    }
    else
      return '';
  }

  const handleChangeZoom = (event, item) => {
    onZoom(item.id);
  };

  const handleAdd = () => {
    history.push('/device');
  }

  return (
      <List className={classes.list}>
      <div className={classes.grow}>
          <AppBar position="static">
            <Toolbar>
              <div className={classes.search}>
                <div className={classes.searchIcon}>
                  <SearchIcon />
                </div>
                <InputBase
                    placeholder={t('selectDevice')}
                    classes={{
                      root: classes.inputRoot,
                      input: classes.inputInput,
                    }}
                    onChange={handleChange}
                    value={deviceName}
                    inputProps={{ 'aria-label': 'search' }}
                />
              </div>
              <div className={classes.grow} />
              <Tooltip title={t("addNewDevice")}>
              <Fab size="small" color="primary" className={classes.fab} onClick={handleAdd}>
                <AddIcon/>
              </Fab>
              </Tooltip>
            </Toolbar>
          </AppBar>
        </div>

        {filteredItems.map((item, index, list) => (
            <Paper style={{margin:10}}  key={item.id}>
              <ListItem button key={item.id} onClick={(event) => {handleChangeZoom(event, item)}}>
                <ListItemAvatar>
                  <Avatar style={{"backgroundColor":getStatusColor(item.status) }} >
                    <img  className={classes.icon}   src={`images/icon/${item.category || 'default'}.svg`} alt="" />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText primary={item.name} secondary={item.uniqueId} className={item.configured !== "configured" ? classes.configue : ''}/>
                <ListItemSecondaryAction>

                  <Grid style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
                    <IconButton onClick={(event) => onMenuClick(event.currentTarget, item.id)}>
                      <MoreVertIcon />
                    </IconButton>
                    <SignalCellularAltIcon style={{ fontSize: 16, verticalAlign: 'middle', marginLeft: 5, color: color(item.id), transform: `scale(${2})` }} />
                  </Grid>


                </ListItemSecondaryAction>
              </ListItem>

              <Grid style={{  display: 'flex', justifyContent: 'space-between' }}>

                <ListItemText
                    style={{ margin: 10, color:getStatusColor(item.status)}}
                    primary={ t(item.status)}
                    secondary={ timeDuration(item)}
                />
                <Grid >
                  <IconButton
                      size="medium"
                      className={classes.margin}
                      color="primary"
                      onClick={() => {
                        handleClick(item.positionId);
                      }}
                  >
                    <ListAltIcon/>
                  </IconButton>
                  <IconButton
                      size="medium"
                      className={classes.margin}
                      color="primary"
                      onClick={() => {
                        onZoom(item.id)
                      }
                      }
                  > <LocationOnIcon />
                  </IconButton>
                  <IconButton
                      size="medium"
                      className={classes.margin}
                      color="primary"
                      onClick={() => history.push(`replay/${item.id}`)}
                  > <ReplayIcon/>
                  </IconButton>
                </Grid>
              </Grid>
            </Paper>
        ))}
      </List>
  );
}

const DevicesList = () => {
  return (
      <EditCollectionViewDevice content={DeviceView}  editPath="/device" endpoint="devices" />
  );
}

export default DevicesList;
