const traccarWhite = '#FFF';
const traccarPurple = '#333366';
const traccarGreen = '#4CAF50';
const traccarRed = '#CC2222';
const traccarGray = '#888888';
const traccarYellow= '#ffff00';

export default {
  common: {
    purple: traccarPurple,
    green: traccarGreen,
    red: traccarRed,
    gray: traccarGray,
    yellow: traccarYellow,
  },
  primary: {
    main: traccarPurple,
  },
  secondary: {
    main: traccarGreen,
    contrastText: traccarWhite,
  },
};
