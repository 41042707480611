import React, { useState } from 'react';
import { Grid, Button, TextField, Typography, Link, makeStyles, Snackbar } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import StartPage from './../../StartPage';
import t from './../../common/localization';
import Alert from "@material-ui/lab/Alert";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles(theme => ({
  register: {
    fontSize: theme.spacing(3),
    fontWeight: 500
  },
  link: {
    fontSize: theme.spacing(3),
    fontWeight: 500,
    marginTop: theme.spacing(0.5),
    cursor: 'pointer'
  },
  loading: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'fixed',     // Fixed positioning to stay on top
    top: '90%',            // Center vertically
    left: '50%',           // Center horizontally
    transform: 'translate(-50%, -50%)', // Center the element
    zIndex: theme.zIndex.tooltip + 1,   // Ensure it appears above other elements
    backgroundColor: 'rgba(255, 255, 255, 0.8)', // Optional: Add background color for better visibility
    padding: theme.spacing(2), // Optional: Add some padding
    borderRadius: theme.shape.borderRadius, // Optional: Rounded corners
  },
}));

const RegisterForm = () => {

  const classes = useStyles();
  const history = useHistory();

  const [organization, setOrganization] = useState('');
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [userLimit] = useState(0);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [reppet, setReppet] = useState(0);
  const [msg, setmsg] = useState('');
  const [loading, setLoading] = useState(false);

    const submitDisabled = () => {
    return !organization || !name || !/(.+)@(.+)\.(.{2,})/.test(email) || !password;
  }

    const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
    };

    const handleRegister = async () => {
      if (password.length < 6){
        setSnackbarOpen(true);
        setmsg(t('lessSixCharacter'))
        return;
      }
      setReppet(reppet+1)
      if (reppet === 4) {
          history.push('/login')
          return;
      }
      let subadmin = true;
      const userData = {  name, email, password, userLimit, subadmin};
      const userOrg = {organization, password, user: userData}
      setLoading(true)

      const response = await fetch('/api/users/addnewadmin', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(userOrg)
    });
    if (response)
      setLoading(false)
    if (response.ok) {
      setSnackbarOpen(true);
      setmsg('')
      //setInterval(() => history.push('/login'), 10000)
    }
    else if (response.status === 400){
        const errorText = await response.text();
        setSnackbarOpen(true);
        setmsg(t(errorText.split(' ')[0]))
    }
    else {
        setSnackbarOpen(true);
        setmsg(t('UnknownProblem'))
    }
  }


    return (
      <StartPage>
          {msg === "" ?
              <Snackbar
                  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                  open={snackbarOpen}
                  // onClose={() => history.push('/login')}
                  // autoHideDuration={30000}
                  message={
                      <React.Fragment>
                          {t('loginCreated')}
                          <br/>
                          {t('loginCreated2')}
                          <br/>
                          <Button color="secondary" size="small" onClick={handleRegister}>
                              Re-send email
                          </Button>
                      </React.Fragment>
                  }
              />
              :
              <Snackbar open={snackbarOpen} autoHideDuration={12000} onClose={handleCloseSnackbar} >
                  <Alert onClose={handleCloseSnackbar} variant="filled" severity="error">
                      {msg}
                  </Alert>
              </Snackbar>
          }
        <Grid container direction='column' spacing={3}>
          <Grid container item>
            <Grid item>
              <Typography className={classes.link} color='primary'>
                <Link onClick={() => history.push('/login')}>
                  <ArrowBackIcon />
                </Link>
              </Typography>
            </Grid>
            <Grid item xs>
              <Typography className={classes.register} color='primary'>
                {t('loginRegister')}
              </Typography>
            </Grid>
          </Grid>
          <Grid item>
            <TextField
                required
                fullWidth
                label={t('userOrganization')}
                name='organization'
                value={organization || ''}
                autoComplete='organization'
                autoFocus
                onChange={event => setOrganization(event.target.value)}
                variant='filled' />
          </Grid>
          <Grid item>
            <TextField
                required
                fullWidth
                label={t('sharedName')}
                name='name'
                value={name || ''}
                autoComplete='name'
                onChange={event => setName(event.target.value)}
                variant='filled' />
          </Grid>
          <Grid item>
            <TextField
                required
                fullWidth
                type='email'
                label={t('userEmail')}
                name='email'
                value={email || ''}
                autoComplete='email'
                onChange={event => setEmail(event.target.value)}
                variant='filled' />
          </Grid>
          <Grid item>
            <TextField
                required
                fullWidth
                label={t('userPassword')}
                name='password'
                value={password || ''}
                type='password'
                autoComplete='current-password'
                onChange={event => setPassword(event.target.value)}
                variant='filled' />
          </Grid>
          {/*<Grid item>*/}
          {/*  <TextField*/}
          {/*      required*/}
          {/*      fullWidth*/}
          {/*      label={"userLimit"}*/}
          {/*      name='userLimit'*/}
          {/*      value={userLimit || ''}*/}
          {/*      autoComplete='userLimit'*/}
          {/*      autoFocus*/}
          {/*      onChange={event => setUserLimit(event.target.value)}*/}
          {/*      variant='filled' />*/}
          {/*</Grid>*/}
          <Grid item>
            <Button
                variant='contained'
                color="secondary"
                onClick={handleRegister}
                disabled={submitDisabled()}
                fullWidth>
              {t('loginRegister')}
            </Button>
          </Grid>
        </Grid>
        {loading && <div className={classes.loading}>
          <CircularProgress color="secondary" />
        </div>}
      </StartPage>
  )
}

export default RegisterForm;
