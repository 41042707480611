import React, { useState } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { sessionActions } from './store';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import MenuIcon from '@material-ui/icons/Menu';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import ListSubheader from '@material-ui/core/ListSubheader';
import Divider from '@material-ui/core/Divider';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import MapIcon from '@material-ui/icons/Map';
import BarChartIcon from '@material-ui/icons/BarChart';
import PeopleIcon from '@material-ui/icons/People';
import StorageIcon from '@material-ui/icons/Storage';
import PersonIcon from '@material-ui/icons/Person';
import NotificationsIcon from '@material-ui/icons/Notifications';
import DescriptionIcon from '@material-ui/icons/Description';
import FolderIcon from '@material-ui/icons/Folder';
import CreateIcon from '@material-ui/icons/Create';
import ReplayIcon from '@material-ui/icons/Replay';
import BuildIcon from '@material-ui/icons/Build';
import t from './common/localization';
import { useEffectAsync } from './reactHelper';
import {Avatar, Grid} from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import Tooltip from "@material-ui/core/Tooltip";
import {organizationActions} from "./store";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { Link } from 'react-router-dom';
const useStyles = makeStyles(theme => ({
  flex: {
    flexGrow: 1
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
  },
  list: {
    width: 250
  },
  menuButton: {
    marginLeft: -12,
    marginRight: 20,
  },
  menuButtonMobile: {
    marginLeft: -5,
    marginRight: 0,
  },
  logoSmall: {
    width: 50,
    height: 50,
  },
  backArrowIconContainer: {
    '&:hover': {
      backgroundColor:"transparent",
    },
    color: 'white',
  },
  backArrowIconContainerMobile: {
    '&:hover': {
      backgroundColor:"transparent",
    },
    color: 'white',
    position: 'absolute',
    left: theme.spacing(-2),
  },
}));

const MainToolbar = () => {
  const dispatch = useDispatch();
  const [drawer, setDrawer] = useState(false);
  const classes = useStyles();
  const history = useHistory();
  const adminEnabled = useSelector(state => state.session.user && state.session.user.administrator);
  const subadminEnabled = useSelector(state => state.session.user && state.session.user.subadmin)
  const userId = useSelector(state => state.session.user && state.session.user.id);
  const location = useLocation();
  const organizationInfo =  useSelector(state => state.organization_information.org ? state.organization_information.org : false);
  const [isMobile, setIsMobile] = useState(false);
  const isSmallScreen = useMediaQuery('(max-width:600px)');


  const openDrawer = () => {setDrawer(true) }

  const closeDrawer = () => { setDrawer(false) }

  const handleLogout = async () => {
    const response = await fetch('/api/session', { method: 'DELETE' });
    if (response.ok) {
      dispatch(sessionActions.updateUser(null));
      document.cookie = await 'JSESSIONID=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
      history.push('/login');
    }
  }

    useEffectAsync(async () => {
        if (!organizationInfo) {
            const response = await fetch('/api/users/getorg');
            if (response.ok) {
                let res = await response.json()
                dispatch(organizationActions.updateOrg(res))
            }
        }
        setIsMobile(isSmallScreen);
    }, [isSmallScreen]);

  function toBlob(base64Data) {
    const binaryData = atob(base64Data);
    const arrayBuffer = new ArrayBuffer(binaryData.length);
    const uint8Array = new Uint8Array(arrayBuffer);
    for (let i = 0; i < binaryData.length; i++) {
      uint8Array[i] = binaryData.charCodeAt(i);
    }
    const blob = new Blob([arrayBuffer], { type: 'image/png' }); // change the MIME type to match the actual image type
    return blob;
  }

  const isMainPage = location.pathname === '/';
  const accountLink = "/user/"+userId;

    return (
      <>
        <AppBar position="static" className={classes.appBar}>
          <Toolbar>
            {!isMainPage &&
            <IconButton
                onClick={() => history.push('/')}
                className={!isMobile ? classes.backArrowIconContainer : classes.backArrowIconContainerMobile}
                disableRipple>
              <ArrowBackIcon />
            </IconButton>}
            <IconButton
                className={!isMobile ? classes.menuButton : classes.menuButtonMobile}
                color="inherit"
                onClick={openDrawer}>
              <MenuIcon />
            </IconButton>

            <Typography variant="h6" color="inherit" className={classes.flex}>
              Tracthing
            </Typography>

            {organizationInfo && organizationInfo.length > 0 &&
            <Typography variant="h6" color="inherit"  className={!isMobile && classes.flex}>
              {organizationInfo[0].organization.toUpperCase()}
            </Typography>
            }

            {adminEnabled &&
            <Typography variant="h6" color="inherit"  className={!isMobile && classes.flex}>
              {adminEnabled && (t('superAdmin'))}
            </Typography>
            }

            <Avatar>
              <Grid item xs={12}>
                <label htmlFor="logo-upload">
                  {organizationInfo && organizationInfo[0] && organizationInfo[0].logo ? (
                      <Link to={accountLink}>
                          <img src={URL.createObjectURL(toBlob(organizationInfo[0].logo))} alt="Organization Logo" className={`${classes.logo} 
                      ${classes.logoSmall}`} />
                      </Link>
                  ) : (    ''   )}
                  {adminEnabled &&
                  <Link to={accountLink}>
                  <img  src='/tracthing.PNG' alt="SuperAdmin Logo" style={{"width":39}}/>
                  </Link>
                  }
                </label>
              </Grid>
            </Avatar>
              <Tooltip title={t("exit")}>
                  <Button color="inherit" onClick={handleLogout} className={classes.logoutButton}> <ExitToAppIcon /></Button>
              </Tooltip>
          </Toolbar>
        </AppBar>

        <Drawer open={drawer} onClose={closeDrawer}>
          <div
              tabIndex={0}
              className={classes.list}
              role="button"
              onClick={closeDrawer}
              onKeyDown={closeDrawer}>
            <List>
              <ListItem button onClick={() => history.push('/')}>
                <ListItemIcon>
                  <MapIcon />
                </ListItemIcon>
                <ListItemText primary={t('mapTitle')} />
              </ListItem>
              <ListItem button onClick={() => history.push('/replay')}>
                <ListItemIcon>
                  <ReplayIcon />
                </ListItemIcon>
                <ListItemText primary={t('reportReplay')} />
              </ListItem>
              <ListItem button onClick={() => history.push('/reports/route')}>
                <ListItemIcon>
                  <DescriptionIcon />
                </ListItemIcon>
                <ListItemText primary={t('reportTitle')} />
              </ListItem>
            </List>
            <Divider />
            <List
                subheader={
                  <ListSubheader>
                    {t('settingsTitle')}
                  </ListSubheader>
                }
            >
              <ListItem button disabled={!userId} onClick={() => history.push(`/user/${userId}`)}>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={t('settingsUser')} />
              </ListItem>
              <ListItem button onClick={() => history.push('/geofences')}>
                <ListItemIcon>
                  <CreateIcon />
                </ListItemIcon>
                <ListItemText primary={t('sharedGeofences')} />
              </ListItem>
              <ListItem button onClick={() => history.push('/settings/notifications')}>
                <ListItemIcon>
                  <NotificationsIcon />
                </ListItemIcon>
                <ListItemText primary={t('sharedNotifications')} />
              </ListItem>
              <ListItem button onClick={() => history.push('/settings/groups')}>
                <ListItemIcon>
                  <FolderIcon />
                </ListItemIcon>
                <ListItemText primary={t('settingsGroups')} />
              </ListItem>
              <ListItem button onClick={() => history.push('/settings/drivers')}>
                <ListItemIcon>
                  <PersonIcon />
                </ListItemIcon>
                <ListItemText primary={t('sharedDrivers')} />
              </ListItem>
              <ListItem button onClick={() => history.push('/settings/attributes')}>
                <ListItemIcon>
                  <StorageIcon />
                </ListItemIcon>
                <ListItemText primary={t('sharedComputedAttributes')} />
              </ListItem>
              <ListItem button onClick={() => history.push('/settings/maintenances')}>
                <ListItemIcon>
                  <BuildIcon />
                </ListItemIcon>
                <ListItemText primary={t('sharedMaintenance')} />
              </ListItem>
            </List>
            {subadminEnabled && (
                <>
                  <Divider />
                  <List subheader={
                        <ListSubheader>
                          {t('userAdmin')}
                        </ListSubheader>}
                  >
                    {adminEnabled && (
                      <ListItem button onClick={() => history.push('/admin/server')}>
                        <ListItemIcon>
                          <StorageIcon />
                        </ListItemIcon>
                        <ListItemText primary={t('settingsServer')} />
                      </ListItem>)}
                    <ListItem button onClick={() => history.push('/admin/users')}>
                      <ListItemIcon>
                        <PeopleIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('settingsUsers')} />
                    </ListItem>
                    <ListItem button onClick={() => history.push('/admin/statistics')}>
                      <ListItemIcon>
                        <BarChartIcon />
                      </ListItemIcon>
                      <ListItemText primary={t('statisticsTitle')} />
                    </ListItem>
                  </List>
                </>
            )}
          </div>
        </Drawer>
      </>
  );
}
export default MainToolbar;
