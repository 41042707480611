import { createSlice } from '@reduxjs/toolkit';

const { reducer, actions } = createSlice({
    name: 'org',
    initialState: {org: null,},
    reducers: {
        updateOrg(state, action) {
            state.org = action.payload;
        },
    }
});

export { actions as organizationActions };
export { reducer as organizationReducer };
