import { createSlice } from '@reduxjs/toolkit'

const { reducer, actions } = createSlice({
    name : 'notifications',
    initialState : {notifications: [],},
    reducers: {
        updateNotifications(state, action){
            state.notifications = action.payload;
        },
    }
})

export { actions as notificationsActions };
export { reducer as notificationsReducer };