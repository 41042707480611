export const deviceCategories = [
  'default',
  'animal',
  'bicycle',
  'boat',
  'bus',
  'car',
  'crane',
  'helicopter',
  'motorcycle',
  'offroad',
  'person',
  'pickup',
  'plane',
  'ship',
  'tractor',
  'train',
  'tram',
  'trolleybus',
  'truck',
  'van',
  'scooter',
];
